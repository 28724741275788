export const environment = {
  appName: 'vertera_dev_tr',
  production: false,
  multilangServer: false,
  baseUri: 'https://api.new.vertera.org/ru/v1',
  host: 'https://dev.turkey.vertera.org/',
  staticUrl: 'https://static.vertera.org',
  angiolive: {
    token: '0a91d583-1ae7-495c-bfc3-e20fc055a20d',
    url: 'https://landings.dev.vertera.org/angiolive',
    paySuccessUrl: '/land/angiolive/payment?success=',
    yaMetrika: 55999003,
    b: {
      token: 'ec90ebd1-5b9c-4898-b865-5c9c8716e8cc',
    }
  },
  thalassolab: {
    token: '8b367642-fafd-42ca-8f3f-3ace1534baae',
    url: 'https://landings.dev.vertera.org/thalassolab',
    paySuccessUrl: '/land/thalassolab/payment?success=',
    mapsApiKey: 'AIzaSyC9ei_wsWjYkVpAMJkiuWq3ufsnkAhPvmg',
    yaMetrika: 56761948,
  },
  sensation: {
    token: 'e5c6bd03-d248-4193-8a4b-4a551fddf7dd',
    url: 'https://landings.dev.vertera.org/sensation',
    paySuccessUrl: '/land/sensation?success=',
  },
  miracle: {
    token: 'b967a72b-4e80-4818-930b-c54b2535dfe2',
    url: 'https://landings.dev.vertera.org/miracle',
    paySuccessUrl: '/land/miracle?success=',
  },
  verteraGel: {
    token: '95a6012c-3b55-4b50-b90b-bdf247fbbbb7',
    url: 'https://landings.dev.vertera.org/vertera-gel',
    yaMetrika: 56041366,
  },
  detoxJuice: {
    token: '314b15ef-ebb4-480f-a187-7ec005f0c5f7',
    url: 'https://landings.dev.vertera.org/detox-juice',
  },
  vertera: {
    yaMetrika: 61615645,
    token: '4c53b891-4230-4918-862f-7bda9abd7141',
    url: 'https://old.dev.vertera.org',
  },
  turkey: {
    url: 'https://dev.turkey.vertera.org',
  },
  azerbaijan: {
    url: 'https://dev.azer.vertera.org/az/',
  },
  eu: {
    url: 'https://dev.eu.vertera.org/',
  },
  macedonia: {
    url: 'https://dev.macedon.vertera.org/mk/',
  },
  serbia: {
    url: 'https://dev.serb.vertera.org/sr/',
  },
  global: {
    url: 'https://dev.vertera.global/',
  },
  authLink: {
    registration: 'https://dev.id.verteraturkiye.com/registration?ref=ad&service=verteratr',
    login: 'https://dev.id.verteraturkiye.com/?ref=ad&service=verteratr',
    idlogout: 'https://dev.id.verteraturkiye.com/logout?return=https://dev.turkey.vertera.org/logout',
    lk: 'https://os.verteraturkiye.com/mystats.php',
  },
  newECA: {
    registration: 'https://id.boss-dev1.vertera.org/register?service=VERTERA_NEW',
    login: 'https://id.boss-dev1.vertera.org/?service=VERTERA_NEW',
    idlogout: `https://id.boss-dev1.vertera.org/logout?service=VERTERA_NEW&return=`,
    lk: 'https://os.verteraturkiye.com/mystats.php',
  },
  vertera_turkey: {
    yaMetrika: 62142838,
    token: 'c3c7a8db-cfbe-4952-873d-5d1cf9b18134',
  },
  hc: {
    url: 'https://landings.dev.vertera.org/tr/hc/'
  },
  refundLink: 'https://old.dev.vertera.org/en/retuns-refunds-policy',
  mapsApiKey: 'AIzaSyC9ei_wsWjYkVpAMJkiuWq3ufsnkAhPvmg',
  os3Uri: 'https://backend.boss-dev1.vertera.org',
  showProfileEditButton: true,
};
